var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "soundkit" },
    [
      _c(
        "router-link",
        {
          staticClass: "soundkit__topWrapper",
          attrs: { to: { name: "SoundKit", params: { id: _vm.soundkit.id } } },
        },
        [
          _c("img", {
            staticClass: "soundkit__image",
            attrs: { src: _vm.soundkit.image_url },
          }),
          _c("icon", {
            staticClass: "soundkit__icon",
            style: {
              backgroundColor: _vm.elitepage.soundkit_bg_color,
              color: _vm.textColorFromBackground(
                _vm.elitepage.soundkit_bg_color
              ),
            },
            attrs: { name: "search" },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "soundkit__price" }, [
        _vm._v("$" + _vm._s(_vm.soundkit.price)),
      ]),
      _c("div", { staticClass: "soundkit__title" }, [
        _vm._v(_vm._s(_vm.soundkit.name) + " "),
      ]),
      _c(
        "router-link",
        {
          staticClass: "soundkit__button btn btn--primary",
          attrs: { to: { name: "SoundKit", params: { id: _vm.soundkit.id } } },
        },
        [_vm._v("DETAILS")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }